import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import {
  GetStarted,
  Features,
  Services,
  Hero,
  AboutTeam,
} from './components';

import ContactUs from '../../components/ContactUs';

const IndexView = (): JSX.Element => {
  const theme = useTheme();
  return (
      <Main bgcolor={'background.paper'}>
          <Box
            sx={{
              backgroundImage: `linear-gradient(to bottom, ${alpha(
                theme.palette.background.paper,
                0,
              )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
              backgroundRepeat: 'repeat-x',
              position: 'relative',
              paddingTop: '5rem'
            }}
          >
            <Container>
              <Hero />
            </Container>
            <Box
              component={'svg'}
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
              sx={{
                width: '100%',
                marginBottom: theme.spacing(-1),
              }}
            >
              <path
                fill={theme.palette.background.paper}
                d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
              ></path>
            </Box>
          </Box>
        <Container id="services">
          <Services />
        </Container>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >

          <Grid item xs={3}>
            <ContactUs />
          </Grid>
        </Grid> 
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0,
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container id="about">
            <AboutTeam />
            <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={3}>
              <ContactUs />
            </Grid>
          </Grid> 
          </Container>
          <Container id="analytics">
            <Features />
          </Container>
          <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
        <Container>
          <GetStarted />
        </Container>
      </Main>
  );
};

export default IndexView;

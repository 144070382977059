import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import ContactUs from 'components/ContactUs';
import Grid from '@mui/material/Grid';

import HomeHero from 'images/real-house.png';

import Container from 'components/Container';

const Hero = (): JSX.Element => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
    >
      <Box paddingY={{ xs: 0, sm: '4rem' }} paddingX={{ xs: 0, sm: '2rem' }}>
        <Grid container spacing={4}>
          <Grid item container xs={12} md={6} order={{ xs: 2, sm: 2, md: 1 }} alignItems={'center'}>
            <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
              <Box marginBottom={2}>
                <Typography
                  variant="h2"
                  color="text.primary"
                  sx={{ fontWeight: 700 }}
                >
                  Turn your Home
                  < br />
                  into{' '}
                  <Typography
                    color={'primary'}
                    component={'span'}
                    variant={'inherit'}
                  >
                    <Typed
                      strings={['cash.', 'a vacation.', 'a new start.']}
                      typeSpeed={100}
                      loop={true}
                    />
                  </Typography>
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Typography variant="h6" component="p" color="text.secondary">
                  Northwoods REI will make selling your home as easy as possible and professional while
                  saving you precious time.
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
              >
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth={isMd ? false : true}
                >
                  Case studies
                </Button> */}
                <ContactUs />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            order={{ xs: 1, sm: 1, md: 2 }}
            alignItems={'center'}
            justifyContent={'center'}
            xs={12}
            md={6}
            sx={{
              '& .lazy-load-image-background.lazy-load-image-loaded': {
                width: '100%',
                height: '100%',
              }
            }}
          >
            <Box
              component={LazyLoadImage}
              height={1}
              width={1}
              src={HomeHero}
              alt="..."
              effect="blur"
              borderRadius={2}
              maxWidth={1200}
              maxHeight={600}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Hero;
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Customer from 'images/northwoods-couple-2.png'; /* eslint-disable-line */

const AboutTeam = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={600} width={1}>
            <Box
              component={'img'}
              src={Customer}
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          data-aos={isMd ? 'fade-right' : 'fade-up'}
          alignItems={'center'}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
                Leading Real Estate Office in KC
            </Typography>
            <Typography
              variant={'h6'}
              component={'p'}
              color={'text.secondary'}
              gutterBottom
            >
              We deal with sellers in all situations and properties in all conditions.
            </Typography>
            <Typography variant={'h6'} component={'p'} color={'text.secondary'}>
              Whether you’re a tired landlord looking
              to free yourself from the headaches caused by unruly and disrespectful tenants,
              an out-of-stater that inherited a property that’s become a costly burden,
              someone who’s having trouble keeping up with bills and/or life’s other demands,
              or any other situation we’re here to help and make a custom-tailored solution for you!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutTeam;

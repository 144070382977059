// import React from 'react';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import InputAdornment from '@mui/material/InputAdornment';
// import FormControl from '@mui/material/FormControl';
// import { makeStyles } from "@mui/material//styles";
// import { Grid } from '@mui/material';
// import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
// import Fab from "@mui/material/Fab";
// import MenuItem from "@mui/material/MenuItem";


// const useStyles = makeStyles(theme => ({
//   label: {
//     color: "white",
//     height: 55,
//     marginRight: -14,
//   },
//   address: {
//     width: "80%",
//   },
//   inputColor: {
//     color: "white",
//   },
//   form: {
//     width: "100%",
//     display: "flex",
//     alignItems: "baseline",
//     justifyContent: "space-evenly",
//   },
//   fab: {
//     position: "fixed",
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
// }))

// const GetStartedForm = ({ showAddress = false, fabStyle = false }) => {
//   const classes = useStyles();


//   const states = [
//     "Alabama",
//     "Alaska",
//     "Arizona",
//     "Arkansas",
//     "California",
//     "Colorado",
//     "Connecticut",
//     "Delaware",
//     "District of Columbia",
//     "Florida",
//     "Georgia",
//     "Hawaii",
//     "Idaho",
//     "Illinois",
//     "Indiana",
//     "Iowa",
//     "Kansas",
//     "Kentucky",
//     "Louisiana",
//     "Maine",
//     "Maryland",
//     "Massachusetts",
//     "Michigan",
//     "Minnesota",
//     "Mississippi",
//     "Missouri",
//     "Montana",
//     "Nebraska",
//     "Nevada",
//     "New Hampshire",
//     "New Jersey",
//     "New Mexico",
//     "New York",
//     "North Carolina",
//     "North Dakota",
//     "Ohio",
//     "Oklahoma",
//     "Oregon",
//     "Pennsylvania",
//     "Rhode Island",
//     "South Carolina",
//     "South Dakota",
//     "Tennessee",
//     "Texas",
//     "Utah",
//     "Vermont",
//     "Virginia",
//     "Washington",
//     "West Virginia",
//     "Wisconsin",
//     "Wyoming"
//   ]

//   const [open, setOpen] = React.useState(false);
//   const [firstName, setFirstName] = React.useState();
//   const [lastName, setLastName] = React.useState();
//   const [address, setAddress] = React.useState('');
//   const [email, setEmail] = React.useState();
//   const [phone, setPhone] = React.useState();
//   const [city, setCity] = React.useState();
//   const [state, setState] = React.useState('');
//   const [zipcode, setZipcode] = React.useState();

//   const handleClickOpen = () => {
//     setOpen(true);
//   }

//   const handleClose = () => {
//     setOpen(false);
//   }
  
//   const handleState = (event: any) => {
//     setState(event.target.value);
//   }

//   const handleSubmit = (e: any) => {
//     console.log(`${firstName} ; ${email} ; ${address}`);
//     e.preventDefault();
//     const body = {
//       firstName,
//       lastName,
//       address,
//       city,
//       state,
//       zipcode,
//       email,
//       phone,
//     };

//     fetch('/.netlify/functions/get-started', {
//       method: 'POST',
//       body: JSON.stringify(body),
//     })
//       .then(() => setOpen(false))
//       .catch(() => setOpen(false));
//   }

//   return (
//     <div style={{ width: '100%' }}>
//       { showAddress 
//       && (
//       <Grid container justify="center" direction="row" alignItems="center">
//         <Grid item xs={12} direction="row" justify="center" alignItems="center">
//           <FormControl className={classes.form}>
//             <TextField
//               id="enterAddress"
//               className={classes.address}
//               label="Street Address"
//               placeholder="123 Parkway"
//               variant="outlined"
//               value={address}
//               required
//               onChange={(e: any) => setAddress(e.target.value)}
//               InputProps={{
//                 classes:{ input: classes.inputColor },
//                 endAdornment: <InputAdornment position="end"><Button variant="contained" color="secondary" style={{ color: "white", height: 55, marginRight: -14, }} onClick={handleClickOpen}>Get Started</Button></InputAdornment>
//               }}
//             />
//           </FormControl>
//         </Grid>
//       </Grid>
//       )}
//       {!showAddress && !fabStyle 
//       && (
//         <Button variant="outlined" color="secondary" onClick={handleClickOpen} size="large">
//           Get Started
//         </Button>
//       )}
//       { fabStyle && (
//         <Fab color="secondary" size="small" onClick={handleClickOpen} className={classes.fab}>
//           <ContactSupportOutlinedIcon />
//         </Fab>
//       )}
//       <Dialog open={open} onClose={handleClose} aria-labelledby="get-started">
//         <DialogTitle id="form-title">Get Started Today by Submitting Your Information!</DialogTitle>
//         <DialogContent>
//           <Grid container justify="center" direction="row" alignItems="center" spacing={1}>
//             <Grid item xs={6}>
//               <TextField
//                 variant="outlined"
//                 autoFocus
//                 margin="normal"
//                 id="firstName"
//                 label="First Name"
//                 type="name"
//                 color="secondary"
//                 fullWidth
//                 required
//                 value={firstName}
//                 onChange={(e: any) => setFirstName(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 id="lastName"
//                 label="Last Name"
//                 type="name"
//                 color="secondary"
//                 fullWidth
//                 required
//                 value={lastName}
//                 onChange={(e: any) => setLastName(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField 
//                 variant="outlined"
//                 margin="normal"
//                 id="address"
//                 label="Address"
//                 type="address"
//                 color="secondary"
//                 fullWidth
//                 required
//                 value={address}
//                 onChange={(e: any) => setAddress(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField 
//                 variant="outlined"
//                 margin="normal"
//                 id="city"
//                 label="City"
//                 type="city"
//                 color="secondary"
//                 fullWidth
//                 required
//                 value={city}
//                 onChange={(e: any) => setCity(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <TextField 
//                 variant="outlined"
//                 margin="normal"
//                 id="zipcode"
//                 label="Zip Code"
//                 type="zip"
//                 color="secondary"
//                 fullWidth
//                 required
//                 value={zipcode}
//                 onChange={(e: any) => setZipcode(e.target.value)}
//               />
//             </Grid>
//             <Grid item xs>
//             <TextField
//               label="State"
//               variant="outlined"
//               fullWidth
//               required
//               select
//               SelectProps={{
//                 value: state,
//                 onChange: handleState,
//               }}
//             >
//               {states.map((st) => (
//                 <MenuItem key={st} value={st}>
//                   {st}
//                 </MenuItem>
//               ))}
//             </TextField>
//             </Grid>
//           </Grid>
//           <TextField 
//             variant="outlined"
//             margin="normal"
//             id="email"
//             label="Email"
//             type="email"
//             color="secondary"
//             fullWidth
//             required
//             value={email}
//             onChange={(e: any) => setEmail(e.target.value)}
//           />
//           <TextField 
//             variant="outlined"
//             margin="normal"
//             id="phone"
//             label="Phone Number"
//             type="phone"
//             color="secondary"
//             fullWidth
//             required
//             value={phone}
//             onChange={(e: any) => setPhone(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>
//             Cancel
//           </Button>
//           <Button onClick={handleSubmit} color="secondary">
//             Submit
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   )
// }

// export default GetStartedForm;



import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import DialogContentText from '@mui/material/DialogContentText';

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, open, ...other } = props;

  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [address, setAddress] = React.useState('');
  const [email, setEmail] = React.useState();
  const [phone, setPhone] = React.useState();
  const [city, setCity] = React.useState();
  const [state, setState] = React.useState('');
  const [zipcode, setZipcode] = React.useState();
  const [value, setValue] = React.useState(valueProp);

  const firstNameRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (firstNameRef.current != null) {
      firstNameRef.current.focus();
    }
  };

  const handleState = (event: any) => {
    setState(event.target.value);
  }

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleSubmit = (e: any) => {
    console.log(`${firstName} ; ${email} ; ${address}`);
    e.preventDefault();
    const body = {
      firstName,
      lastName,
      address,
      city,
      state,
      zipcode,
      email,
      phone,
    };

    fetch('/.netlify/functions/get-started', {
      method: 'POST',
      body: JSON.stringify(body),
    })
      .then(() => handleOk())
      .catch((error) => console.log(error));
  };

  return (
    <div>
        <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%' } }}
        maxWidth="sm"
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
        >
        <DialogTitle>Submit your information and we'll be in touch shortly!</DialogTitle>
        <DialogContent dividers>
          <Grid container justifyContent="center" direction="row" alignItems="center" spacing={1}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                ref={firstNameRef}
                margin="normal"
                id="firstName"
                label="First Lame"
                type="name"
                fullWidth
                required
                value={firstName}
                onChange={(e: any) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="lastName"
                label="Last Name"
                type="name"
                fullWidth
                required
                value={lastName}
                onChange={(e: any) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                variant="outlined"
                margin="normal"
                id="address"
                label="Address"
                type="address"
                fullWidth
                required
                value={address}
                onChange={(e: any) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                variant="outlined"
                margin="normal"
                id="city"
                label="City"
                type="city"
                fullWidth
                required
                value={city}
                onChange={(e: any) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                variant="outlined"
                margin="normal"
                id="zipcode"
                label="Zip Code"
                type="zip"
                fullWidth
                required
                value={zipcode}
                onChange={(e: any) => setZipcode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="State"
                margin="normal"
                variant="outlined"
                fullWidth
                required
                select
                SelectProps={{
                value: state,
                onChange: handleState,
                }}
              >
                {states.map((st) => (
                <MenuItem key={st} value={st}>
                {st}
                </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField 
                variant="outlined"
                margin="normal"
                id="email"
                label="Email"
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                variant="outlined"
                margin="normal"
                id="phone"
                type="number"
                label="Phone Number"
                color="secondary"
                fullWidth
                required
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} 
                value={phone}
                onChange={(e: any) => setPhone(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContentText style={{ textAlign: 'center', margin: '10px' }}>
          By submitting this form, you consent to receive marketing text messages from Northwoods REI and its affiliates.
        </DialogContentText>
        <DialogActions>
            <Button variant="outlined" color="info" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>Send</Button>
        </DialogActions>
        </Dialog>
    </div>
  );
}

export default function ConfirmationDialog({center = false}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('Dione');
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <Box 
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems={{ xs: 'stretched', sm: 'flex-start' }}
      justifyContent={center ? 'center' : 'left'}
      sx={{ bgcolor: 'background.paper' }}
    >
        <Button
          component={'a'}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth={isMd ? false : true}
          onClick={handleClickListItem}
        >
          Get Started
        </Button>
        <ConfirmationDialogRaw
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />
    </Box>
  );
}

